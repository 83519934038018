"use strict";

window.requestAnimFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
        window.setTimeout(callback, 1000 / 60);
    };
}();

function Sjkroll($el, options) {
    this.element = $el;
    this.part = options.part;
    this.breakpoint = options.breakpoint;
    this.direction = options.direction;
    this.initialize();
}

Sjkroll.prototype.initialize = function () {
    var self = this;
    this.windowHeight = window.innerHeight;
    this.checkPosition();
    this.addEventHandlers();
};
Sjkroll.prototype.addEventHandlers = function () {
    var self = this;
    window.addEventListener("scroll", function () {
        this.animFrame = requestAnimFrame(function () {
            self.checkPosition();
        });
    });
    window.addEventListener("DOMMouseScroll", function () {
        self.checkPosition();
    });
};
Sjkroll.prototype.checkPosition = function () {
    var self = this;
    var posFromTop = this.element.getBoundingClientRect().top;

    if (posFromTop - this.windowHeight <= -50) {
        if (this.breakpoint !== undefined && window.innerWidth < this.breakpoint) {
            self.part.style.transform = '';
        } else {
            if (self.direction == 'vertical') {
                self.part.style.transform = 'translateY(' + parseInt(posFromTop / 15 - 10) + 'px)';
            }
            if (self.direction == 'verticalReverse') {
                self.part.style.transform = 'translateY(' + parseInt(-posFromTop / 10 + 10) + 'px)';
            }
        }
    }
};
function Iteminview($el, options) {
    this.element = $el;
    this.initialize();
}

Iteminview.prototype.initialize = function () {
    var self = this;
    this.windowHeight = window.innerHeight;
    this.checkPosition();
    this.addEventHandlers();
};
Iteminview.prototype.addEventHandlers = function () {
    var self = this;
    window.addEventListener("scroll", function () {
        self.checkPosition();
    });
    window.addEventListener("DOMMouseScroll", function () {
        self.checkPosition();
    });
};
Iteminview.prototype.checkPosition = function () {
    var self = this;
    var posFromTop = self.element.getBoundingClientRect().top;
    var $item = self.element;
    if (posFromTop - this.windowHeight <= -50) {
        var delay = 100;
        if (window.scrollY == 0) {
            delay = 1500;
        }
        setTimeout(function () {
            $item.classList.add('inview');
        }, delay);
    } else {
        if ($item.classList.contains('repeat')) {
            setTimeout(function () {
                $item.classList.remove('inview');
            }, 100);
        }
    }
};
/* 
    This is your main JS file (frontend). Don't rename it. You can split up your script into sepperate files inside the src/javascript/scripts/ folder.
    Vendors will be bundled and prepended before all of your own javascript. Stick your vendor js files inside the src/javascript/vendor/ folder.
    Make sure to declare all the vendors and custom scripts inside the projectConfig.js file.
*/
document.addEventListener('DOMContentLoaded', function () {
    // let hero = document.getElementsByClassName("hero")[0];
    var header = document.getElementsByClassName("c-site-header")[0];
    var hero = document.getElementsByClassName("hero")[0];

    var headerObj = {
        $el: header,
        $hero: hero,
        locked: false,
        scroll: 0,
        clearAmount: 0,
        isFixed: false,
        continueDown: true,
        continueUp: false,
        lockedOn: 0
    };
    var mainmenu = document.querySelector('.main-nav-desktop');
    var menulinks = mainmenu.getElementsByClassName('onpage');
    var anchors = document.getElementsByClassName("anchor");

    // common helper functions
    var forEach = function forEach(array, callback, scope) {
        for (var i = 0; i < array.length; i++) {
            callback.call(scope, i, array[i]);
        }
    };

    function handleScroll() {
        var hh = headerObj.$el.clientHeight;
        var he = hero.clientHeight;

        if (window.pageYOffset < he - 60) {
            header.classList.add('diap');
        } else {
            header.classList.remove('diap');
        }

        if (window.pageYOffset > 100) {
            headerObj.locked = false;
        } else {
            headerObj.locked = true;
        }
        if (window.pageYOffset > 10) {
            if (window.pageYOffset > headerObj.scroll) {
                // we go down
                headerObj.clearAmount = headerObj.clearAmount - (window.pageYOffset - headerObj.scroll);
                if (headerObj.clearAmount < 0) {
                    headerObj.clearAmount = 0;
                }
                if (headerObj.isFixed == true) {
                    if (headerObj.continueDown == false) {
                        headerObj.$el.style.position = 'absolute';
                        headerObj.$el.style.top = pageYOffset + 'px';
                        headerObj.continueUp = true;
                        headerObj.isFixed = false;
                    }
                }
                headerObj.continueDown = true;
            } else {
                headerObj.clearAmount = headerObj.clearAmount + (headerObj.scroll - window.pageYOffset);
                if (headerObj.clearAmount > hh) {
                    headerObj.clearAmount = hh;
                }
                if (headerObj.continueDown == true) {
                    headerObj.$el.style.top = pageYOffset - (hh - headerObj.clearAmount) + 'px';
                    headerObj.lockedOn = pageYOffset - (hh - headerObj.clearAmount);
                    headerObj.continueDown = false;
                } else {
                    if (headerObj.isFixed == false) {
                        if (pageYOffset < headerObj.lockedOn) {
                            headerObj.$el.style.top = 0;
                            headerObj.$el.style.position = 'fixed';
                            headerObj.isFixed = true;
                        }
                    }
                }
                headerObj.continueUp = true;
            }
        } else {
            headerObj.$el.style.top = 0 + 'px';
        }
        headerObj.scroll = window.pageYOffset;
    }

    function setMenuClassByScroll(id, bool) {
        forEach(menulinks, function (index, item) {
            var str = item.href;
            var re = /#(.*)/;
            var found = str.match(re);
            if (found !== null && found[1] == id) {
                if (bool) {
                    item.parentNode.classList.add('current-menu-item');
                } else {
                    item.parentNode.classList.remove('current-menu-item');
                }
            }
        });
    }

    function setScrollClasses() {
        forEach(anchors, function (index, item) {
            $el = item;
            var viewportOffset = $el.getBoundingClientRect();
            var h = $el.clientHeight;
            var top = viewportOffset.top;
            var diff = top - 60;
            var id = item.id;
            if (diff < 60 && diff > -h) {
                setMenuClassByScroll(id, true);
            }
            if (diff > 60) {
                setMenuClassByScroll(id, false);
            }
            if (diff < -h) {
                setMenuClassByScroll(id, false);
            }
        });
    }
    window.addEventListener("scroll", function () {
        setScrollClasses();
        handleScroll();
    });
    window.addEventListener("DOMMouseScroll", function () {
        setScrollClasses();
        handleScroll();
    });
    setTimeout(function () {
        setScrollClasses();
        handleScroll();
    }, 0);

    // item in view listener
    var $inviewitems = document.getElementsByClassName("item-in-view");
    if ($inviewitems.length > 0) {
        for (var i = 0; i < $inviewitems.length; i++) {
            var iv = new Iteminview($inviewitems[i], {
                row: false
            });
        }
    }

    // lazy loading
    function loadImage($image) {
        if ("IntersectionObserver" in window) {
            var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        var lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.classList.remove("load");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });
            lazyImageObserver.observe($image);
        } else {
            // Possibly fall back to a more compatible method here
        }
    };
    var $loadimages = document.getElementsByClassName("load");
    if ($loadimages.length > 0) {
        for (var i = 0; i < $loadimages.length; i++) {
            loadImage($loadimages[i]);
        }
    }

    // navigation
    function initNavigationListeners() {
        // first add raf shim
        window.requestAnimFrame = function () {
            return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        }();

        var mobilemenu = document.getElementsByClassName('main-nav-mobile')[0];
        var mobileNav = mobilemenu.getElementsByTagName('nav')[0];
        var mobilemenulinks = mobileNav.getElementsByClassName('onpage');
        var menuToggler = document.getElementsByClassName("menu-toggler")[0];
        var mobilemenulogo = document.querySelector('.mobile-logo');
        var arrowDown = document.querySelector('.arrow-down');

        if (arrowDown !== null) {
            arrowDown.onclick = function (e) {
                scrollToY(window.outerHeight - 60, 800, 'easeInOutQuint');
            };
        }

        menuToggler.onclick = function (e) {
            if (document.body.classList.contains('menu-open')) {
                document.body.classList.remove('menu-open');
                // When the modal is shown, we want a fixed body
                var scrollY = document.body.style.top;
                document.body.style.top = '';
                document.body.style.position = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            } else {
                // console.log(window.scrollY);
                document.body.classList.add('menu-open');
                // When the modal is shown, we want a fixed body
                document.body.style.top = "-" + window.scrollY + "px";
                document.body.style.position = 'fixed';
            }
        };

        forEach(menulinks, function (index, item) {
            var str = item.href;
            var re = /#(.*)/;
            var found = str.match(re);
            if (found !== null) {
                item.onclick = function (e) {
                    document.body.classList.remove('menu-open');
                    e.preventDefault();
                    scrollTo(found[1]);
                };
            }
        });

        forEach(mobilemenulinks, function (index, item) {
            var str = item.href;
            var re = /#(.*)/;
            var found = str.match(re);
            item.onclick = function (e) {
                e.preventDefault();
                document.body.classList.remove('menu-open');
                var scrollY = document.body.style.top;
                document.body.style.top = '';
                document.body.style.position = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
                setTimeout(function () {
                    scrollTo(found[1]);
                }, 600);
            };
        });

        var linkMenuLogo = function linkMenuLogo() {
            var str = mobilemenulogo.href;
            var re = /#(.*)/;
            var found = str.match(re);
            mobilemenulogo.onclick = function (e) {
                e.preventDefault();
                scrollTo(found[1]);
            };
        };
        linkMenuLogo();

        function scrollTo(id) {
            $el = document.getElementById(id);
            var viewportOffset = $el.getBoundingClientRect();
            var top = $el.getBoundingClientRect().top;
            // var Yoffset = window.pageYOffset;
            var diff = top + window.scrollY;
            // scroll it!
            scrollToY(diff - (header.clientHeight - 1), 2000, 'easeInOutQuint');
            setTimeout(function () {
                if (!headerObj.isFixed) {
                    headerObj.$el.style.transform = "translateY(-100px)";
                    setTimeout(function () {
                        headerObj.clearAmount = 60;
                        headerObj.$el.style.top = window.pageYOffset + 'px';
                        setTimeout(function () {
                            headerObj.$el.style.transform = "translateY(0)";
                        }, 100);
                    }, 500);
                }
            }, 400);
        }

        // main function
        function scrollToY(scrollTargetY, speed, easing) {
            var scrollY = window.pageYOffset,
                scrollTargetY = scrollTargetY || 0,
                speed = speed || 2000,
                easing = easing || 'easeOutSine',
                currentTime = 0;

            // min time .1, max time .8 seconds
            var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

            // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
            var PI_D2 = Math.PI / 2,
                easingEquations = {
                easeOutSine: function easeOutSine(pos) {
                    return Math.sin(pos * (Math.PI / 2));
                },
                easeInOutSine: function easeInOutSine(pos) {
                    return -0.5 * (Math.cos(Math.PI * pos) - 1);
                },
                easeInOutQuint: function easeInOutQuint(pos) {
                    if ((pos /= 0.5) < 1) {
                        return 0.5 * Math.pow(pos, 5);
                    }
                    return 0.5 * (Math.pow(pos - 2, 5) + 2);
                }
            };

            // add animation loop
            function tick() {
                currentTime += 1 / 60;

                var p = currentTime / time;
                var t = easingEquations[easing](p);

                if (p < 1) {
                    requestAnimFrame(tick);
                    window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
                } else {
                    window.scrollTo(0, scrollTargetY);
                }
            }

            // call it once to get started
            tick();
        }
    }

    initNavigationListeners();
});